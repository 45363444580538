<template>
  <component :is="as || 'li'" class="flex w-full items-center gap-2.5 text-sm">
    <UiIcon name="search" class="size-4 shrink-0" aria-hidden="true" />
    <nuxt-link
      :to="productLink"
      class="flex-auto py-5 font-light leading-[16px]"
      v-html="titleWithHighlight"
    />
  </component>
</template>

<script setup lang="ts">
import { SearchProduct } from "ecom-types";

const props = defineProps<{
  as?: string;
  product: SearchProduct;
  search: string;
}>();

const localePath = useLocalePathPolyfill();

const titleWithHighlight = computed(() => {
  const safeHighlight = props.search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const re = new RegExp(safeHighlight, "gi");
  return props.product.title.replace(re, "<span class='font-normal'>$&</span>");
});

const productLink = computed(() => {
  return localePath({
    path: `/product/${props.product?.slug}`,
    query: {
      configuration_id: props.product?.configuration_id,
      size: "default",
    },
  });
});
</script>
